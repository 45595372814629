import React, { useState, useEffect } from "react";
import styled from "styled-components";
import api from "../lib/api";
import PageHeader from "../components/PageHeader";

const ButtonGroup = styled.div`
  display: flex;
  margin: 30px;
  width: 45%;
`;

const TabButton = styled.button`
  flex: 1;
  padding: 5px 0;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  background-color: ${({ active }) => (active ? "#dde5f4" : "#f0f0f0")};
  color: ${({ active }) => (active ? "#000" : "#555")};
  font-weight: ${({ active }) => (active ? "bold" : "normal")};
  &:not(:last-child) {
    margin-right: 5px;
  }
`;

const PostContainer = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  margin-left: 30px;
  margin-right: 30px;
  text-align: left;
`;

const PostDate = styled.div`
  font-size: 12px;
  color: #888;
  margin-bottom: 5px;
`;

const PostTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const PostDescription = styled.div`
  font-size: 15px;
  color: #555;
`;

const CommentContainer = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  text-align: left;
  margin-left: 30px;
  margin-right: 30px;
`;

export default function MyArticlePage() {
  const [activeTab, setActiveTab] = useState("posts");
  const [userData, setUserData] = useState(null);

  // 토큰을 이용해 내 정보 불러오기
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api().get("/me");
        setUserData(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("API 호출 실패:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <PageHeader title="내가 작성한 글" />
      <ButtonGroup>
        <TabButton
          active={activeTab === "posts"}
          onClick={() => setActiveTab("posts")}
        >
          작성한 게시글
        </TabButton>
        <TabButton
          active={activeTab === "comments"}
          onClick={() => setActiveTab("comments")}
        >
          작성한 댓글
        </TabButton>
      </ButtonGroup>

      {activeTab === "posts" && userData?.posts?.length > 0
        ? userData.posts.map((post) => (
            <PostContainer key={post.id}>
              {/* 게시글 제목 */}
              <PostTitle>{post.title}</PostTitle>
              {/* 게시글 날짜 추가 (예시로 빈 데이터 사용) */}
              <PostDate>2024-11-24</PostDate>
              {/* 추가적인 내용이 필요할 경우 여기에 추가 */}
            </PostContainer>
          ))
        : null}

      {activeTab === "comments" && userData?.comments?.length > 0
        ? userData.comments.map((comment) => (
            <CommentContainer key={comment.id}>
              {/* 댓글이 달린 게시글 제목 */}
              <PostTitle>{comment.which_post.title}</PostTitle>
              {/* 댓글 내용 */}
              <PostDescription>{comment.content}</PostDescription>
            </CommentContainer>
          ))
        : null}
    </div>
  );
}
