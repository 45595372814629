import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import PostPreview from "../components/PostPreview";
import api from "../lib/api";
import PageHeader from "../components/PageHeader";
import Button from "../components/Button";
import { AuthContext } from "../contexts/auth";

const MainPage = () => {
  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);

  // Fetch posts
  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const res = await api().get("/posts", { params: { limit: 20 } });
        setPosts(res.data.posts);
      } catch (err) {
        console.error(err);
        alert(err);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <div className="border-x border-b">
      <PageHeader
        title="게시판"
        addOnRight={
          user && (
            <NavLink to="/posts/write">
              <Button>글쓰기</Button>
            </NavLink>
          )
        }
      />

      {loading ? (
        <div className="p-4 flex items-center justify-center">Loading...</div>
      ) : (
        <div>
          {posts.map((post, i) => (
            <React.Fragment key={post.id}>
              <PostPreview post={post} />
              {i + 1 < posts.length && <div className="border-b" />}
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export default MainPage;
