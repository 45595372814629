import { twMerge } from "tailwind-merge";

export default function Button({ children, className, ...props }) {
  return (
    <button
      className={twMerge(
        "bg-blue-500 text-white text-sm rounded px-3 py-1.5 disabled:text-gray-200 disabled:bg-blue-300 disabled:cursor-not-allowed",
        className,
      )}
      {...props}
    >
      {children}
    </button>
  );
}
