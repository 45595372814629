import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useImmer } from "use-immer";
import { ChevronLeft, Vote } from "lucide-react";

import api from "../lib/api";
import PageHeader from "../components/PageHeader";
import Button from "../components/Button";
import SurveySubPage from "./SurveySubPage";

export default function EditPage() {
  const { postId } = useParams();

  const [post, setPost] = useImmer({
    author_disclosed_attributes: [],
  });
  const [attributeCategories, setAttributeCategories] = useState([]);
  const [newSurvey, setNewSurvey] = useState(undefined);
  const [surveyVisible, setSurveyVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const res = await api().get(`/posts/${postId}`);
        setPost(res.data.post);
      } catch (err) {
        console.error(err);
        alert(err);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const res = await api().get("/attributes");
        setAttributeCategories(res.data.categories);
      } catch (err) {
        console.error(err);
        alert(err);
      }
    })();
  }, []);

  const handleSubmit = async () => {
    if (!post.title?.trim() || !post.content?.trim()) {
      alert("제목과 내용을 모두 입력해주세요.");
      return;
    }

    try {
      await api().put(`/posts/${postId}`, { ...post, survey: newSurvey });
      navigate(`/posts/${postId}`);
    } catch (err) {
      console.error(err);
      alert(err);
    }
  };

  if (!post.survey && surveyVisible) {
    return (
      <SurveySubPage
        survey={newSurvey}
        attributeCategories={attributeCategories}
        onConfirm={(survey) => {
          setNewSurvey(survey);
          setSurveyVisible(false);
        }}
        onClose={() => setSurveyVisible(false)}
      />
    );
  }

  return (
    <div>
      <PageHeader
        title="게시글 수정"
        addOnLeft={
          <button onClick={() => navigate(-1)}>
            <ChevronLeft />
          </button>
        }
        addOnRight={<Button onClick={handleSubmit}>완료</Button>}
      />

      <div className="p-4">
        <input
          className="w-full block px-3 py-2 border-0 text-lg font-semibold outline-none"
          value={post.title ?? ""}
          onChange={(e) =>
            setPost((post) => {
              post.title = e.target.value;
            })
          }
          placeholder="제목을 입력해주세요."
        />
        <div className="border-b" />
        <textarea
          className="mb-6 w-full min-h-80 block px-3 py-2 border-0 outline-none resize-none leading-6"
          value={post.content ?? ""}
          onChange={(e) =>
            setPost((post) => {
              post.content = e.target.value;
            })
          }
          placeholder="내용을 입력해주세요."
        />
        {post.survey ? (
          <div className="mx-3 mb-3 px-4 py-2 flex items-center bg-gray-100 border rounded text-sm">
            <Vote className="mr-3 text-blue-500" />
            <div>
              <div className="-mb-0.5">투표가 첨부되었습니다.</div>
              <small>글 등록 이후에는 수정할 수 없습니다.</small>
            </div>
          </div>
        ) : (
          <Button
            className="bg-transparent text-blue-500"
            onClick={() => setSurveyVisible(true)}
          >
            <Vote />
          </Button>
        )}
      </div>
    </div>
  );
}
