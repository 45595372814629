import dayjs from "dayjs";
import "dayjs/locale/ko";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);
dayjs.locale("ko");

export function prettyDate(date) {
  const d = dayjs(date);
  return d.fromNow();
}
