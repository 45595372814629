import React, { useEffect, useState } from "react";
import { QRCodeSVG } from "qrcode.react";

import PageHeader from "../components/PageHeader";
import api from "../lib/api";

export default function SignUpPage() {
  const [signupInfo, setSignupInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const res = await api().post("/register");
        setSignupInfo(res.data);
      } catch (error) {
        console.error("회원가입 요청 중 오류가 발생했습니다.", error);
        alert("회원가입 중 오류가 발생했습니다.");
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <div>
      <PageHeader title="회원가입" />

      <div className="flex flex-col px-24 pt-6">
        <h1 className="text-xl font-semibold pb-3 border-b flex justify-center">
          회원가입 인증 QR 코드
        </h1>

        {loading ? (
          <div className="flex justify-center mt-8">
            {/* 로딩 중 표시: 회전 애니메이션 추가 */}
            <div className="w-8 h-8 border-4 border-t-4 border-gray-500 border-solid rounded-full animate-spin mb-8"></div>
          </div>
        ) : (
          signupInfo && (
            <div className="flex flex-col items-center mt-8 mb-8 ">
              {/* 큐알코드를 화면 정중앙에 위치시키고 크기를 설정 */}
              <QRCodeSVG value={signupInfo.short_invitation_url} size={256} />
              <a
                className="mt-4 text-blue-500"
                href={`didcomm://${signupInfo.invitation_url}`}
              >
                지갑에서 열기
              </a>
            </div>
          )
        )}
      </div>
    </div>
  );
}
