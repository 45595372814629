import { getAttributeLabel } from "../lib/attributes";

export default function AttributeBadge({ category, value }) {
  const label = getAttributeLabel(category, value);
  return (
    <span className="px-2 py-0.5 bg-blue-200 text-black rounded-full text-xs">
      {label}
    </span>
  );
}
