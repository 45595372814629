import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import styled from "styled-components";
import { ChevronDown, ChevronRight, TriangleAlert, Vote } from "lucide-react";
import { twMerge } from "tailwind-merge";

import api from "../lib/api";
import { prettyDate } from "../lib/date";
import PageHeader from "../components/PageHeader";
import {
  getAttributeCategoryLabel,
  getAttributeLabel,
} from "../lib/attributes";
import { AuthContext } from "../contexts/auth";
import Button from "../components/Button";
import AttributeBadge from "../components/AttributeBadge";

export default function PostPage() {
  const { postId } = useParams();

  const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [selectedSurveyOptions, setSelectedSurveyOptions] = useState([]); // 초기 상태를 빈 배열로 설정
  const [viewingResults, setViewingResults] = useState(false); // 결과 미리보기 상태 추가
  const [newComment, setNewComment] = useState(""); // 댓글 입력 상태
  const [targetVisible, setTargetVisible] = useState(false); // 설문 타겟 보이기 상태
  const [selectedCommentAttributes, setSelectedCommentAttributes] = useState(
    [],
  ); // 공개할 신원 속성
  const [lockedAttributes, setLockedAttributes] = useState([]); // 고정된 신원 공개 항목
  const [replyTargetId, setReplyTargetId] = useState(null); // 대댓글의 대상 댓글 ID
  const [newReply, setNewReply] = useState(""); // 대댓글 입력 상태
  const [selectedReplyAttributes, setSelectedReplyAttributes] = useState([]); // 공개할 대댓글 신원 속성
  const [editingCommentId, setEditingCommentId] = useState(null); // 수정 중인 댓글 ID
  const [editedContent, setEditedContent] = useState(""); // 수정 중인 댓글 내용
  const { user } = useContext(AuthContext);

  const canVote = post?.survey?.can_vote && !post?.survey?.voted;
  const showResult = viewingResults || !canVote;
  const filteredCondition = post?.survey?.condition.filter(
    ({ values }) => values.length > 0,
  );

  const canComment = lockedAttributes.every((lockedAttr) =>
    user?.attributes.find(({ category }) => category === lockedAttr),
  );

  const fetchPost = async () => {
    try {
      const res = await api().get(`/posts/${postId}`);
      const { post } = res.data;
      setPost(post);
      if (post && post.disclosed_attributes) {
        setSelectedCommentAttributes(post.disclosed_attributes);
        setSelectedReplyAttributes(post.disclosed_attributes);
        setLockedAttributes(post.disclosed_attributes);
      }
    } catch (err) {
      console.error(err);
      alert(err);
    }
  };

  useEffect(() => {
    fetchPost();
  }, []);

  if (!post) {
    return <div>Loading...</div>;
  }

  const handleDeletePost = async () => {
    if (window.confirm("게시글을 삭제하시겠습니까?")) {
      try {
        await api().delete(`/posts/${postId}`);
        navigate("/");
      } catch (err) {
        console.error(err);
        alert(err);
      }
    }
  };

  /* ---------------- 설문 관련 ------------------*/

  // 투표하기 버튼 클릭시
  const handleVote = async () => {
    if (!user) {
      alert("로그인이 필요한 서비스입니다.");
      return;
    }

    if (!post.survey.can_vote) {
      alert("설문 대상이 아니므로 투표할 수 없습니다.");
      return;
    }

    if (selectedSurveyOptions.length === 0) {
      alert("옵션을 선택해주세요.");
      return;
    }

    const options = {
      option: selectedSurveyOptions,
    };

    try {
      await api().post(`/posts/${postId}/survey`, options);
      await fetchPost();
    } catch (err) {
      console.error("투표 처리가 실패했습니다.", err);
      alert(err);
    }
  };

  /* ---------------- 댓글 관련 ------------------*/

  // 댓글 등록 핸들러
  const handleCommentSubmit = async () => {
    if (!user) {
      alert("로그인이 필요한 서비스입니다.");
      return;
    }

    if (!newComment.trim()) {
      alert("댓글 내용을 입력해주세요.");
      return;
    }

    // 사용자가 선택한 신원 공개 항목과 사용자 정보에서의 속성 비교
    const missingAttributes = selectedCommentAttributes.filter(
      (attr) => !user.attributes.some((userAttr) => userAttr.category === attr),
    );

    // 필요한 신원 속성이 없으면 에러 메시지 출력
    if (missingAttributes.length > 0) {
      alert(
        `${missingAttributes.join(", ")}(들)에 대해 신원인증을 해야 합니다.`,
      );
      return;
    }

    try {
      if (newComment.length > 500) {
        alert("댓글 수 제한(500자)을 초과했습니다.");
        return; // 길이가 초과하면 더 이상 진행하지 않음
      }

      // 댓글 데이터 생성
      const commentData = {
        parentId: null,
        // author_id: userData.id, // 사용자의 이름을 예시로 사용
        author_disclosed_attributes: selectedCommentAttributes, // 선택된 신원 정보
        content: newComment,
      };

      console.log(JSON.stringify(commentData, null, 2));

      // axios 인스턴스 사용
      await api().post(`/posts/${postId}/comments`, commentData);

      fetchPost();
      setNewComment(""); // 입력 필드 초기화
    } catch (error) {
      console.error("댓글 작성 중 오류 발생", error);
      alert("댓글 작성에 실패했습니다.");
    }
  };

  // 대댓글 등록 핸들러
  const handleReplySubmit = async (parentId) => {
    if (!user) {
      alert("로그인이 필요한 서비스입니다.");
      return;
    }

    if (!newReply.trim()) {
      alert("댓글 내용을 입력하세요.");
      return;
    }

    // 사용자가 선택한 신원 공개 항목과 사용자 정보에서의 속성 비교
    const missingAttributes = selectedReplyAttributes.filter(
      (attr) => !user.attributes.some((userAttr) => userAttr.category === attr),
    );

    // 필요한 신원 속성이 없으면 에러 메시지 출력
    if (missingAttributes.length > 0) {
      alert(
        `${missingAttributes.join(", ")}(들)에 대해 신원인증을 해야 합니다.`,
      );
      return;
    }

    try {
      if (newReply.length > 500) {
        alert("댓글 수 제한(500자)을 초과했습니다.");
        return; // 길이가 초과하면 더 이상 진행하지 않음
      }

      // 대댓글 데이터
      const replyData = {
        parent_id: parentId, // 대댓글의 부모 ID 설정
        content: newReply,
        author_disclosed_attributes: selectedReplyAttributes, // 선택된 신원 정보
      };

      console.log(JSON.stringify(replyData, null, 2));

      // axios 인스턴스 사용
      await api().post(`/posts/${postId}/comments`, replyData);

      setNewReply(""); // 입력창 초기화
      setReplyTargetId(null); // 대댓글 입력창 닫기
      fetchPost();
    } catch (error) {
      alert("글자수가 초과했습니다.");
      console.error("대댓글 작성 실패:", error);
    }
  };

  const handleReplyClick = (commentId) => {
    setReplyTargetId(replyTargetId === commentId ? null : commentId); // 클릭 시 토글
  };

  // 댓글 수정 버튼 클릭 시 호출
  const handleEditCommentClick = (commentId, currentContent) => {
    setEditingCommentId(commentId); // 수정 중인 댓글 ID 설정
    setEditedContent(currentContent); // 현재 댓글 내용을 수정 필드에 설정
  };

  // 댓글 수정 완료 버튼 클릭 시 호출
  const handleEditCommentSubmit = async (commentId) => {
    try {
      if (editedContent.length > 500) {
        alert("댓글 수 제한(500자)을 초과했습니다.");
        return; // 길이가 초과하면 더 이상 진행하지 않음
      }

      // 댓글 데이터 생성
      const editedCommentData = {
        content: editedContent,
      };

      console.log(editedCommentData);

      await api().put(
        `/posts/${postId}/comments/${commentId}`,
        editedCommentData,
      );

      setEditingCommentId(null); // 수정 상태 해제
      setEditedContent(""); // 수정 내용 초기화
      fetchPost();
    } catch (error) {
      console.error("댓글 수정 중 오류가 발생했습니다.", error);
      alert("댓글 수정에 실패했습니다.");
    }
  };

  // 댓글 수정 취소 버튼 클릭 시 호출
  const handleCancelEditComment = () => {
    setEditingCommentId(null); // 수정 상태 해제
    setEditedContent(""); // 수정 중이던 내용 초기화
  };

  // 댓글 삭제 핸들러
  const handleDeleteComment = async (commentId) => {
    if (window.confirm("댓글을 삭제하시겠습니까?")) {
      try {
        console.log("commentId:" + commentId + " 삭제");

        // axios 인스턴스 사용
        await api().delete(`/posts/${postId}/comments/${commentId}`);
        fetchPost();
      } catch (error) {
        console.error("댓글 삭제 오류:", error);
        alert("댓글 삭제에 실패했습니다.");
      }
    }
  };

  // 신원 공개 항목 선택 핸들러
  const handleCommentAttributeChange = (attribute) => {
    if (!lockedAttributes.includes(attribute)) {
      setSelectedCommentAttributes((prev) =>
        prev.includes(attribute)
          ? prev.filter((attr) => attr !== attribute)
          : [...prev, attribute],
      );
    }
  };

  // 신원 공개 항목 선택 핸들러
  const handleReplyAttributeChange = (attribute) => {
    if (!lockedAttributes.includes(attribute)) {
      setSelectedReplyAttributes((prev) =>
        prev.includes(attribute)
          ? prev.filter((attr) => attr !== attribute)
          : [...prev, attribute],
      );
    }
  };

  return (
    <div className="border-x border-b">
      <PageHeader title="게시판" />
      <PostContainer>
        <div className="mb-4 flex items-center justify-between">
          <span className="text-sm text-gray-700">
            {prettyDate(post.created_at)}
          </span>
          {post.is_mine && (
            <div className="flex text-sm text-gray-500">
              <Link className="mr-2" to={`/posts/${postId}/edit`}>
                수정
              </Link>
              <button onClick={handleDeletePost}>삭제</button>
            </div>
          )}
        </div>

        <h1 className="mb-2 text-2xl font-semibold break-words">
          {post.title}
        </h1>

        <div className="flex items-center text-sm">
          <span className="mr-2 font-semibold text-gray-700">작성자:</span>
          <div className="flex items-center gap-2">
            {post.author.disclosed_attributes.length > 0 ? (
              post.author.disclosed_attributes.map(({ category, value }) => (
                <AttributeBadge category={category} value={value} key={value} />
              ))
            ) : (
              <AttributeBadge value="익명" />
            )}
          </div>
        </div>

        <div className="mt-5 whitespace-pre-wrap leading-6 text-lg">
          {post.content}
        </div>

        {/* 설문 현황 부분 */}
        {post.survey && (
          <div className="mt-5 flex flex-col p-4 border-2 rounded-xl">
            <div className="mb-3 flex items-center">
              <div className="flex items-center text-blue-500">
                <Vote className="mr-1" />
                <span className="">투표</span>
              </div>
              <span className="mx-2.5 h-5 border-l border-gray-200"></span>
              <span>
                <span className="mr-0.5 font-semibold">
                  {post.survey?.num_voters}명
                </span>
                참여 중...
              </span>
            </div>

            {/* 중복 선택 허용 여부에 따라 텍스트 표시 */}
            <p className="mb-2 text-sm text-gray-500">
              {post.survey.multi_select_enabled
                ? "복수 선택 허용"
                : "복수 선택 불가"}
            </p>

            {/* 설문 대상 */}
            <div className="mb-4">
              {filteredCondition.length > 0 ? (
                <>
                  {user && (
                    <div
                      className={`mb-2 ${post.survey.can_vote ? "text-blue-500" : "text-red-500"} text-sm`}
                    >
                      {!post.survey?.voted &&
                        (post.survey?.can_vote
                          ? "이 설문에 참여할 수 있습니다."
                          : "설문 대상이 아니므로 투표할 수 없습니다.")}
                    </div>
                  )}

                  <button
                    className="flex items-center text-left text-sm"
                    onClick={() => setTargetVisible((visible) => !visible)}
                  >
                    <span className="mr-1">
                      {!targetVisible ? (
                        <ChevronRight size={16} />
                      ) : (
                        <ChevronDown size={16} />
                      )}
                    </span>
                    설문 대상
                  </button>

                  {targetVisible && (
                    <div className="mt-1 flex flex-col gap-1 p-2 border rounded text-sm">
                      {filteredCondition.length > 0
                        ? filteredCondition.map(
                            (condition, i) =>
                              condition.values.length > 0 && (
                                <div key={i}>
                                  <span className="font-bold mr-1">
                                    {getAttributeCategoryLabel(
                                      condition.category,
                                    )}
                                    :
                                  </span>
                                  {condition.values
                                    .map((value) =>
                                      getAttributeLabel(
                                        condition.category,
                                        value,
                                      ),
                                    )
                                    .join(", ")}
                                </div>
                              ),
                          )
                        : "전체"}
                    </div>
                  )}
                </>
              ) : (
                <span className="text-sm text-blue-500">설문 대상: 전체</span>
              )}
            </div>

            <div className="flex flex-col gap-2">
              {post.survey.options.map(({ option, votes }) => {
                if (showResult) {
                  const percentage =
                    votes > 0
                      ? Math.round((votes / post.survey.num_voters) * 100)
                      : 0;

                  return (
                    <div
                      className="relative px-2 py-1.5 bg-gray-100 rounded-lg flex items-center"
                      key={option}
                    >
                      <span className="whitespace-nowrap text-ellipsis overflow-x-hidden">
                        {option}
                      </span>
                      <div className="ml-auto shrink-0">
                        {votes}표 ({percentage}%)
                      </div>
                      <div
                        className="absolute left-0 right-0 h-full rounded-lg bg-blue-500/25"
                        style={{ width: `${percentage}%` }}
                      />
                    </div>
                  );
                }

                return (
                  <label
                    className="px-2 py-1.5 bg-gray-100 flex items-center rounded-lg has-[input:checked]:bg-blue-200/50 select-none cursor-pointer has-[input:disabled]:cursor-not-allowed"
                    key={option}
                  >
                    <input
                      type="checkbox"
                      disabled={!post.survey.can_vote}
                      checked={selectedSurveyOptions.includes(option)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          post.survey.multi_select_enabled
                            ? setSelectedSurveyOptions((options) => [
                                ...new Set([...options, option]),
                              ])
                            : setSelectedSurveyOptions([option]);
                        } else {
                          post.survey.multi_select_enabled
                            ? setSelectedSurveyOptions((options) =>
                                options.filter((o) => o !== option),
                              )
                            : setSelectedSurveyOptions([]);
                        }
                      }}
                      className={twMerge(
                        "relative shrink-0 w-4 h-4 mr-2 border-2 checked:border-blue-500 border-gray-300 rounded-full",
                        "appearance-none shrink-0 cursor-pointer",
                        "checked:before:content-[''] before:absolute before:top-1/2 before:left-1/2 before:w-2 before:h-2",
                        "checked:before:bg-blue-500 before:-translate-x-1/2 before:-translate-y-1/2 before:rounded-full",
                        "pointer-events-none",
                        `${viewingResults ? "hidden" : "block"}`,
                      )}
                    />
                    <span className="whitespace-nowrap text-ellipsis overflow-hidden">
                      {option}
                    </span>
                  </label>
                );
              })}
            </div>

            {canVote && (
              <>
                {viewingResults ? (
                  <Button
                    className="mt-3 bg-transparent text-blue-500"
                    onClick={() => setViewingResults(false)}
                  >
                    투표 참여하기
                  </Button>
                ) : (
                  <>
                    <Button
                      className="mt-3 mb-1.5"
                      disabled={selectedSurveyOptions.length === 0}
                      onClick={handleVote}
                    >
                      투표하기
                    </Button>
                    <Button
                      className="bg-transparent text-blue-500"
                      onClick={() => setViewingResults(true)}
                    >
                      투표 결과 미리 보기
                    </Button>
                  </>
                )}
              </>
            )}

            {post?.survey?.voted && (
              <span className="mt-2 text-xs text-gray-500">
                이미 참여 완료한 설문입니다.
              </span>
            )}
          </div>
        )}
      </PostContainer>

      {/* 댓글 부분 */}
      <CommentContainer>
        {lockedAttributes.length > 0 && (
          <div className="flex items-center mb-4 px-4 py-3 bg-gray-100 rounded text-sm">
            <TriangleAlert className="text-[#ff9393] mr-1.5" size={16} />
            <span>
              <span>이 게시글은 댓글 작성 시&nbsp;</span>
              <span style={{ fontWeight: "bold" }}>
                {lockedAttributes
                  .map((category) => getAttributeCategoryLabel(category))
                  .join(", ")}
              </span>
              을(를) 필수로 공개해야 합니다.
            </span>
          </div>
        )}

        <div className="text-lg mb-3">댓글</div>

        {post.comments && post.comments.length > 0 ? (
          post.comments.map((comment) => (
            <div className="border-t py-3" key={comment.id}>
              <div className="flex items-center gap-2">
                <div className="mb-1 mr-2 whitespace-nowrap">
                  {comment.is_deleted ? (
                    <span className="text-gray-500">삭제된 댓글입니다.</span>
                  ) : (
                    <>익명{comment.author.no}</>
                  )}
                </div>
                {comment.author &&
                  comment.author.disclosed_attributes.map(
                    ({ category, value }) => (
                      <AttributeBadge
                        category={category}
                        value={value}
                        key={value}
                      />
                    ),
                  )}
              </div>

              {editingCommentId === comment.id ? (
                // 수정 모드: textarea와 수정 완료 버튼 표시
                <EditSection>
                  <EditCommentInput
                    value={editedContent}
                    onChange={(e) => setEditedContent(e.target.value)}
                  />
                  <CommentActions
                    className="flex items-center gap-2"
                    style={{ justifyContent: "flex-end" }}
                  >
                    <PostActionButton
                      // style={{ justifyContent: "flex-end" }}
                      onClick={() => handleEditCommentSubmit(comment.id)}
                    >
                      완료
                    </PostActionButton>
                    <PostActionButton onClick={handleCancelEditComment}>
                      취소
                    </PostActionButton>
                  </CommentActions>
                </EditSection>
              ) : (
                // 기본 모드: 댓글 내용과 수정 버튼 표시
                <>
                  <CommentContent className="mb-2">
                    {comment.content}
                  </CommentContent>
                  <CommentDateAndActions className="mb-2">
                    <CommentDate>
                      {!comment.is_deleted && prettyDate(comment.created_at)}
                    </CommentDate>
                    <CommentActions className="flex gap-2">
                      {!comment.is_deleted && canComment && (
                        <ReplyButton
                          onClick={() => handleReplyClick(comment.id)}
                        >
                          대댓글
                        </ReplyButton>
                      )}
                      {comment.is_mine && (
                        <>
                          <button
                            onClick={() =>
                              handleEditCommentClick(
                                comment.id,
                                comment.content,
                              )
                            }
                          >
                            수정
                          </button>
                          <button
                            onClick={() => handleDeleteComment(comment.id)}
                          >
                            삭제
                          </button>
                        </>
                      )}
                    </CommentActions>
                  </CommentDateAndActions>
                </>
              )}

              {/* 대댓글 */}
              {comment.children && comment.children.length > 0 && (
                <ReplyContainer className="bg-gray-50">
                  {comment.children.map((childComment) => (
                    <ReplyWrapper
                      className="pl-4 pr-2 py-2"
                      key={childComment.id}
                    >
                      <div className="mb-1 flex items-center gap-2">
                        <div className="mr-2 whitespace-nowrap">
                          익명{childComment.author.no}
                        </div>
                        {childComment.author &&
                          childComment.author.disclosed_attributes.map(
                            ({ category, value }) => (
                              <AttributeBadge
                                category={category}
                                value={value}
                                key={value}
                              />
                            ),
                          )}
                      </div>

                      {editingCommentId === childComment.id ? (
                        // 대댓글 수정 모드
                        <EditSection>
                          <EditCommentInput
                            value={editedContent}
                            onChange={(e) => setEditedContent(e.target.value)}
                          />
                          <CommentActions
                            className="flex items-center gap-2"
                            style={{ justifyContent: "flex-end" }}
                          >
                            <PostActionButton
                              onClick={() =>
                                handleEditCommentSubmit(childComment.id)
                              }
                            >
                              완료
                            </PostActionButton>
                            <PostActionButton onClick={handleCancelEditComment}>
                              취소
                            </PostActionButton>
                          </CommentActions>
                        </EditSection>
                      ) : (
                        <>
                          <CommentContent className="mb-1">
                            {childComment.content}
                          </CommentContent>
                          <CommentDateAndActions>
                            <CommentDate>
                              {prettyDate(childComment.created_at)}
                            </CommentDate>
                            {childComment.is_mine && (
                              <div className="flex gap-2">
                                <button
                                  onClick={() =>
                                    handleEditCommentClick(
                                      childComment.id,
                                      childComment.content,
                                    )
                                  }
                                >
                                  수정
                                </button>
                                <button
                                  onClick={() =>
                                    handleDeleteComment(childComment.id)
                                  }
                                >
                                  삭제
                                </button>
                              </div>
                            )}
                          </CommentDateAndActions>
                        </>
                      )}
                    </ReplyWrapper>
                  ))}
                </ReplyContainer>
              )}

              {canComment && replyTargetId === comment.id && (
                <ReplyInputContainer>
                  <ReplyInputWrapper>
                    <ReplyInput
                      type="text"
                      value={newReply}
                      onChange={(e) => setNewReply(e.target.value)}
                      placeholder="대댓글을 입력하세요."
                    />
                    <Button
                      className="mt-2"
                      style={{ backgroundColor: "#459EFF" }}
                      onClick={() => handleReplySubmit(comment.id)}
                    >
                      대댓글 등록
                    </Button>

                    {/* 대댓글 신원 공개 체크박스 */}
                    <CheckIdentitiesLabel className="mt-2">
                      내 신원 공개하기
                    </CheckIdentitiesLabel>
                    <CheckIdentitiesWrapper>
                      {user?.attributes.map(({ category }) => (
                        <IdentityItem key={category}>
                          <label>
                            <IdentityCheckbox
                              type="checkbox"
                              checked={selectedReplyAttributes.includes(
                                category,
                              )}
                              onChange={() =>
                                handleReplyAttributeChange(category)
                              }
                              disabled={lockedAttributes.includes(category)} // 고정된 항목 비활성화
                            />
                            {getAttributeCategoryLabel(category)}
                          </label>
                        </IdentityItem>
                      ))}
                    </CheckIdentitiesWrapper>
                  </ReplyInputWrapper>
                </ReplyInputContainer>
              )}
            </div>
          ))
        ) : (
          <div className="mb-4 text-gray-500">댓글이 없습니다.</div>
        )}

        {/* 댓글 입력란 */}
        {user &&
          (canComment ? (
            <>
              <CommentInputWrapper>
                <CommentInput
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
                  placeholder="댓글을 입력하세요."
                  rows="3"
                />
                <Button className="mt-3" onClick={handleCommentSubmit}>
                  댓글 등록
                </Button>
              </CommentInputWrapper>

              {/* 신원 공개하기 체크박스 */}
              <CheckIdentitiesLabel className="mt-2">
                내 신원 공개하기
              </CheckIdentitiesLabel>
              <CheckIdentitiesWrapper>
                {user?.attributes.map(({ category }) => (
                  <IdentityItem key={category}>
                    <label>
                      <IdentityCheckbox
                        type="checkbox"
                        checked={selectedCommentAttributes.includes(category)}
                        onChange={() => handleCommentAttributeChange(category)}
                        disabled={lockedAttributes.includes(category)} // 고정된 항목 비활성화
                      />
                      {getAttributeCategoryLabel(category)}
                    </label>
                  </IdentityItem>
                ))}
              </CheckIdentitiesWrapper>
            </>
          ) : (
            <div className="text-sm text-gray-500">
              참여 조건이 맞지 않아 댓글을 달 수 없습니다.
            </div>
          ))}
      </CommentContainer>
    </div>
  );
}

/* ---------- 게시글 ---------- */
const PostContainer = styled.div`
  padding: 20px 30px 50px 30px;
  max-width: 800px;
  background-color: #fff;
  text-align: left;
`;

const PostActionButton = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  // margin-left: 10px;
  color: #888;
  font-size: 14px;
  cursor: pointer;
`;

/* ---------- 댓글 ---------- */
const CommentContainer = styled.div`
  display: flex;
  flex-direction: column; /* 열 방향으로 정렬 */
  padding: 20px 30px 20px 30px;
  border-top: 10px solid #eff0f4; /* 굵은 테두리 추가 */
  text-align: left;
`;

const ReplyWrapper = styled.div`
  border-top: 1px solid #ddd;
  font-size: 16px;
`;

const ReplyContainer = styled.div`
  display: flex;
  flex-direction: column; /* 열 방향으로 정렬 */
  // padding: 0px 30px 0px 30px;
  // border-top: 1px solid #eff0f4; /* 굵은 테두리 추가 */
  text-align: left;
`;

const CommentContent = styled.div`
  font-size: 16px;
  color: #555;
  word-wrap: break-word;
`;

const CommentInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CommentInput = styled.textarea`
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  resize: none;
  margin-top: 20px;
  line-height: 1.5;
`;

const CommentDateAndActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #888;
`;

const CommentDate = styled.span`
  font-size: 14px;
  color: #888;
`;

const CommentActions = styled.div`
  display: flex;
  // gap: px;
`;

const ReplyButton = styled.button`
  font-size: 14px;
  color: #888;
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const ReplyInputWrapper = styled(CommentInputWrapper)``;

const ReplyInput = styled(CommentInput)``;

const EditSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const EditCommentInput = styled.textarea`
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  resize: none;
  margin-bottom: 10px;
  line-height: 1.5;
`;

/* ---------- 댓글 (신원 공개 선택 부분) ---------- */
const CheckIdentitiesLabel = styled.div`
  font-weight: bold;
  margin-left: 2px;
  // margin-bottom: 5px;
  color: #6a6a6a;
`;

const CheckIdentitiesWrapper = styled.div`
  color: #6a6a6a;
  display: flex;
  justify-items: column;
  align-items: center;
  margin-top: 10px; /* 상단 여백 */
  margin-bottom: 5px;
`;

const IdentityItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const IdentityCheckbox = styled.input`
  margin-right: 8px;
  accent-color: ${({ disabled }) =>
    disabled ? "red" : "blue"}; /* 고정된 항목은 빨간색 */
  cursor: ${({ disabled }) =>
    disabled
      ? "not-allowed"
      : "pointer"}; /* 비활성화 상태일 때 커서 모양 변경 */
`;

const ReplyInputContainer = styled.div`
  border-top: 1px solid #ddd;
  padding: 0 10px 10px 10px;
  background-color: #f8f8f8;
`;
