export const attributeNames = {
  age: {
    label: "연령대",
    values: {
      "20s": "20대",
      "30s": "30대",
      "40s": "40대",
    },
  },
  gender: {
    label: "성별",
    values: {
      male: "남성",
      female: "여성",
    },
  },
  university_entry_year: {
    label: "대학 입학년도",
    // values: {},
  },
};

export function getAttributeCategoryLabel(category) {
  return attributeNames[category]?.label ?? category;
}

export function getAttributeLabel(category, value) {
  const cat = attributeNames[category];
  if (!cat) {
    return value;
  }
  const values = cat.values;
  if (!values) {
    return value;
  }
  return values[value] ?? value;
}
