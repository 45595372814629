import { useImmer } from "use-immer";
import { Plus, X } from "lucide-react";

import {
  getAttributeCategoryLabel,
  getAttributeLabel,
} from "../lib/attributes";
import PageHeader from "../components/PageHeader";
import Button from "../components/Button";

export default function SurveySubPage({
  survey: passedSurvey,
  attributeCategories,
  onConfirm,
  onClose,
}) {
  const [survey, setSurvey] = useImmer(passedSurvey ?? {});
  const [options, setOptions] = useImmer(
    passedSurvey?.options.map(({ option }) => option) ?? ["", ""],
  );

  const handleConditionChange = (category, value) => (e) => {
    setSurvey((survey) => {
      const cat = survey.condition?.find((c) => c.category === category);
      if (e.target.checked) {
        if (!cat) {
          if (!survey.condition) {
            survey.condition = [];
          }
          survey.condition.push({
            category,
            values: [value],
          });
        } else {
          cat.values.push(value);
        }
      } else {
        cat.values = cat.values.filter((v) => v !== value);
        if (cat.values.length === 0) {
          survey.condition = survey.condition.filter(
            (c) => c.category !== category,
          );
        }
      }
    });
  };

  const handleOk = () => {
    if (options.findIndex((option) => option.trim().length === 0) >= 0) {
      alert("빈 항목이 있습니다.");
      return;
    }

    const filteredOptions = options.filter(
      (option) => option.trim().length > 0,
    );
    if (filteredOptions.length === 0) {
      alert("설문조사 항목을 작성해주세요.");
      return;
    }

    onConfirm({
      ...survey,
      multi_select_enabled: survey.multi_select_enabled ?? false,
      options: filteredOptions.map((option) => ({ option })),
    });
  };

  return (
    <div className="border-x border-b">
      <PageHeader
        title="설문 등록"
        addOnLeft={
          <button onClick={onClose}>
            <X />
          </button>
        }
        addOnRight={<Button onClick={handleOk}>완료</Button>}
      />

      <div className="p-4">
        <div className="mb-4">
          <div className="mb-3 text-lg">설문 대상 필터</div>
          <div className="flex flex-col gap-2">
            {attributeCategories.map(({ category, values }) => (
              <div className="flex" key={category}>
                <span className="mr-3 font-semibold whitespace-nowrap">
                  {getAttributeCategoryLabel(category)}:
                </span>
                <div className="flex flex-wrap items-center gap-2">
                  {values.map((value) => (
                    <label
                      className="px-2 py-0.5 bg-gray-100 rounded-full cursor-pointer has-[input:checked]:bg-blue-300 select-none text-sm"
                      key={value}
                    >
                      <input
                        className="hidden"
                        type="checkbox"
                        checked={
                          survey.condition
                            ?.find((c) => c.category === category)
                            ?.values.includes(value) ?? false
                        }
                        onChange={(e) =>
                          handleConditionChange(category, value)(e)
                        }
                      />
                      {getAttributeLabel(category, value)}
                    </label>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="mb-4 border-b" />

        <div className="mb-4">
          <div className="mb-2 flex flex-col gap-2">
            {options.map((option, i) => (
              <div className="flex" key={i}>
                <input
                  className="mr-3 flex-grow px-2 py-1.5 border-2 rounded border-gray-100 focus:border-zinc-400 text-sm"
                  value={option}
                  onChange={(e) =>
                    setOptions((options) => {
                      options[i] = e.target.value;
                    })
                  }
                  required
                  placeholder={`항목 ${i + 1}`}
                />
                <button
                  className="text-gray-500"
                  onClick={() =>
                    setOptions((options) => {
                      options.splice(i, 1);
                    })
                  }
                >
                  <X />
                </button>
              </div>
            ))}
          </div>
          <Button
            className="flex justify-center items-center w-full bg-white text-gray-700 border outline-none"
            onClick={() =>
              setOptions((options) => {
                options.push("");
              })
            }
          >
            <Plus className="mr-2" size={16} />
            항목 추가
          </Button>
        </div>

        <div className="flex justify-end">
          <label className="flex items-center">
            <input
              className="mr-1.5"
              type="checkbox"
              checked={survey?.multi_select_enabled ?? false}
              onChange={(e) =>
                setSurvey((survey) => ({
                  ...survey,
                  multi_select_enabled: e.target.checked,
                }))
              }
            />
            복수 선택 허용
          </label>
        </div>
      </div>
    </div>
  );
}
