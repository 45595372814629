import styled, { keyframes, css } from "styled-components";

export const PostContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 10px 20px;
`;

export const PostTitle = styled.input`
  font-family: "Pretandard";
  padding: 16px 10px;
  border: none;
  border-bottom: 1px solid #d3d3d3;
  font-size: 20px;
  font-weight: bold;
  outline: none;
`;

export const PostContent = styled.textarea`
  font-family: "Pretandard";
  height: 400px;
  padding: 16px 10px;
  border: none;
  border-bottom: 1px solid #d3d3d3;
  resize: none;
  font-size: 18px;
  outline: none;
  line-height: 1.6;
  white-space: pre-wrap; /* 개행을 처리하도록 추가 */
`;

export const VoteButton = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;
  padding: 10px;
`;

export const Banner = styled.div`
  margin: 10px 0;
  padding: 10px;
  background-color: #e0f7fa;
  color: #00695c;
  font-size: 16px;
  border-radius: 5px;
`;

export const PostSettingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #2e2e2e;
`;

export const PostSettingTitle = styled.div`
  text-align: left;
  font-size: 18px;
`;

export const PostSettingOptions = styled.div`
  display: flex;
  gap: 15px;
`;

export const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const OptionLabel = styled.label`
  margin-left: 5px;
  font-size: 14px;
`;

export const OptionCheckbox = styled.input`
  width: 16px;
  height: 16px;
`;

export const slideIn = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const Footer = styled.div`
  animation: ${(props) =>
    props.isVisible
      ? css`
          ${slideIn} 0.5s forwards
        `
      : "none"};
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  pointer-events: ${(props) => (props.isVisible ? "auto" : "none")};
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: ${(props) => (props.isVisible ? "block" : "none")};
  z-index: 99999;
`;
