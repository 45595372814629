export default function PageHeader({ title, addOnLeft, addOnRight }) {
  return (
    <div className="relative flex items-center justify-center h-12 border-b">
      <div className="absolute left-4 h-full flex items-center">
        {addOnLeft}
      </div>
      <span className="text-lg">{title}</span>
      <div className="absolute right-4 h-full flex items-center">
        {addOnRight}
      </div>
    </div>
  );
}
