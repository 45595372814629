import axios from "axios";

const api = () => {
  const accessToken = localStorage.getItem("accessToken");

  return axios.create({
    baseURL: "https://api.blindid.pknu.kr",
    // baseURL: "http://localhost:8080",
    headers: {
      Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
    },
  });
};

export default api;
