import React, { useContext, useEffect } from "react";
import { Routes, Route, Outlet, NavLink, Link } from "react-router-dom";
import { LogIn, LogOut, UserRound, UserRoundPlus } from "lucide-react";

import MainPage from "./pages/MainPage";
import SignUpPage from "./pages/SignUpPage";
import TestLoginPage from "./pages/TestLoginPage";
import WritePostPage from "./pages/WritePostPage";
import PostPage from "./pages/PostPage";
import EditPostPage from "./pages/EditPostPage";
import MyPage from "./pages/MyPage";
import MyArticlePage from "./pages/MyArticlePage";
import MyAttributesPage from "./pages/MyAttributesPage";
import NotFoundPage from "./pages/NotFoundPage";
import AuthorizedPage from "./components/AuthorizedPage";
import { AuthContext } from "./contexts/auth";
import LogoutPage from "./pages/LogoutPage";
import LoginPage from "./pages/LoginPage";

export function RootLayout() {
  const { user } = useContext(AuthContext);

  return (
    <div>
      <nav className="sticky top-0 z-50 h-[60px] border-b bg-white/90 px-4 lg:px-0">
        <div className="bl-wrapper flex h-full items-center justify-between">
          <NavLink className="text-2xl font-bold text-blue-500" to="/">
            blinDID
          </NavLink>
          <div className="flex items-center gap-4">
            {user ? (
              <>
                <Link to="/mypage">
                  <UserRound />
                </Link>
                <Link to="/logout">
                  <LogOut />
                </Link>
              </>
            ) : (
              <>
                <Link to="/signup">
                  <UserRoundPlus />
                </Link>
                <Link to="/login">
                  <LogIn />
                </Link>
              </>
            )}
          </div>
        </div>
      </nav>
      <section id="content" className="bl-wrapper">
        <Outlet />
      </section>
    </div>
  );
}

export default function App() {
  const { fetchUser } = useContext(AuthContext);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return (
    <Routes>
      <Route element={<RootLayout />}>
        <Route index element={<MainPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/login/test" element={<TestLoginPage />} />
        <Route path="/logout" element={<LogoutPage />} />
        <Route
          path="/mypage"
          element={
            <AuthorizedPage>
              <MyPage />
            </AuthorizedPage>
          }
        />
        <Route
          path="/mypage/attributes"
          element={
            <AuthorizedPage>
              <MyAttributesPage />
            </AuthorizedPage>
          }
        />
        <Route
          path="/mypage/articles"
          element={
            <AuthorizedPage>
              <MyArticlePage />
            </AuthorizedPage>
          }
        />
        <Route path="/posts">
          <Route
            path="write"
            element={
              <AuthorizedPage>
                <WritePostPage />
              </AuthorizedPage>
            }
          />
          <Route path=":postId" element={<PostPage />} />
          <Route
            path=":postId/edit"
            element={
              <AuthorizedPage>
                <EditPostPage />
              </AuthorizedPage>
            }
          />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
}
