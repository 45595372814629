import { createContext, useCallback, useState } from "react";

import api from "../lib/api";

export const AuthContext = createContext({
  loaded: false,
  user: null,
  fetchUser: async () => {},
  logout: () => {},
});

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const fetchUser = useCallback(async () => {
    try {
      const res = await api().get("/me");
      setUser(res.data.user);
    } catch (err) {
      console.error(err);
    } finally {
      setLoaded(true);
    }
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem("accessToken");
    setUser(null);
  }, []);

  return (
    <AuthContext.Provider value={{ loaded, user, fetchUser, logout }}>
      {children}
    </AuthContext.Provider>
  );
}
