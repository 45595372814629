import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { QRCodeSVG } from "qrcode.react";

import api from "../lib/api";
import PageHeader from "../components/PageHeader";
import Button from "../components/Button";
import { AuthContext } from "../contexts/auth";

export default function LoginPage() {
  const [loginInfo, setLoginInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const { fetchUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleStartLogin = async () => {
    setLoading(true);
    try {
      const res = await api().post("/login");
      setLoginInfo(res.data);
    } catch (err) {
      console.error(err);
      alert(err);
    } finally {
      setLoading(false);
    }
  };

  const handleCompleteLogin = async () => {
    setLoading(true);
    try {
      const res = await api().post("/login/complete", {
        token: loginInfo.token,
      });
      localStorage.setItem("accessToken", res.data.access_token);
      await fetchUser();
      navigate("/");
    } catch (err) {
      console.error(err);
      alert(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="border-x border-b">
      <PageHeader title="로그인" />

      <div className="p-4">
        {!loginInfo ? (
          <div className="text-center">
            <Button onClick={handleStartLogin} disabled={loading}>
              QR 코드 생성
            </Button>
          </div>
        ) : (
          <div className="flex flex-col items-center">
            <QRCodeSVG
              className="mb-4"
              value={loginInfo.short_invitation_url}
              size={200}
            />
            <a
              className="text-blue-500"
              href={`didcomm://${loginInfo.invitation_url}`}
            >
              지갑에서 열기
            </a>
            <Button onClick={handleCompleteLogin} disabled={loading}>
              로그인 완료
            </Button>
            <small className="mt-2 text-gray-500">
              (지갑에서 인증을 완료한 후 클릭하세요)
            </small>
          </div>
        )}
      </div>
    </div>
  );
}
