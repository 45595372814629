import { MessageSquare, Vote } from "lucide-react";
import { NavLink } from "react-router-dom";

import { prettyDate } from "../lib/date";

export default function PostPreview({ post }) {
  const { id, title, content, num_comments, survey, created_at } = post;

  return (
    <div className="px-4 py-5">
      <span className="relative mb-1 block text-sm text-gray-500">
        {prettyDate(created_at)}
      </span>

      <NavLink
        className="mb-2 flex items-center justify-between"
        to={`/posts/${id}`}
      >
        <span className="mr-3 overflow-hidden text-ellipsis whitespace-nowrap text-lg font-semibold">
          {title}
        </span>
        <div className="flex items-center">
          <MessageSquare className="mr-2" size={16} />
          <span className="text-sm">{num_comments}</span>
        </div>
      </NavLink>

      <div>
        <div className="line-clamp-2 overflow-hidden text-ellipsis text-gray-700">
          {content}
        </div>
        {survey && (
          <div className="mt-3 flex items-center rounded bg-gray-100 p-3 text-sm">
            <div className="flex items-center text-blue-500">
              <Vote className="mr-1" />
              <span className="">투표</span>
            </div>
            <span className="mx-2.5 h-5 border-l border-gray-200"></span>
            <span>
              <span className="mr-0.5 font-semibold">
                {survey.num_voters}명
              </span>
              참여 중...
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
