import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ChevronLeft, Vote } from "lucide-react";
import { useImmer } from "use-immer";

import api from "../lib/api";
import PageHeader from "../components/PageHeader";
import Button from "../components/Button";
import { Footer } from "../styled/WritePageStyles";
import { getAttributeCategoryLabel } from "../lib/attributes";
import SurveySubPage from "./SurveySubPage";
import { AuthContext } from "../contexts/auth";

export default function WritePostPage() {
  const footerRef = useRef(null);
  const { user } = useContext(AuthContext);

  const [post, setPost] = useImmer({
    author_disclosed_attributes: [],
    disclosed_attributes: [],
  });

  const [attributeCategories, setAttributeCategories] = useState([]);
  const [surveyVisible, setSurveyVisible] = useState(false);
  const [footerVisible, setFooterVisible] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const res = await api().get("/attributes");
        setAttributeCategories(res.data.categories);
      } catch (err) {
        console.error(err);
        alert(err);
      }
    })();
  }, []);

  const handleSubmit = async () => {
    if (!post.title?.trim() || !post.content?.trim()) {
      alert("제목과 내용을 모두 입력해주세요.");
      return;
    }
    setFooterVisible(true);
    document.body.style.overflow = "hidden";
  };

  const handleFinalSubmit = async () => {
    try {
      const res = await api().post("/posts", post);
      navigate(`/posts/${res.data.post_id}`);
    } catch (err) {
      console.error(err);
      alert(err);
    }
  };

  if (surveyVisible) {
    return (
      <SurveySubPage
        survey={post.survey}
        attributeCategories={attributeCategories}
        onConfirm={(survey) => {
          setPost((post) => {
            post.survey = survey;
          });
          setSurveyVisible(false);
        }}
        onClose={() => setSurveyVisible(false)}
      />
    );
  }

  return (
    <div className="border-x border-b">
      <PageHeader
        title="글쓰기"
        addOnLeft={
          <button onClick={() => navigate(-1)}>
            <ChevronLeft />
          </button>
        }
        addOnRight={<Button onClick={() => handleSubmit()}>등록</Button>}
      />

      <div className="p-4">
        <input
          className="w-full block px-3 py-2 border-0 text-lg font-semibold outline-none"
          value={post.title ?? ""}
          onChange={(e) =>
            setPost((post) => {
              post.title = e.target.value;
            })
          }
          placeholder="제목을 입력해주세요."
        />
        <div className="border-b" />
        <textarea
          className="w-full min-h-80 block px-3 py-2 border-0 outline-none resize-none leading-6"
          value={post.content ?? ""}
          onChange={(e) =>
            setPost((post) => {
              post.content = e.target.value;
            })
          }
          placeholder="내용을 입력해주세요."
        />
        {post.survey && (
          <div className="mx-3 mb-3 p-3 bg-[#e0f7fa] text-[#00695c] rounded">
            설문이 첨부되었습니다. 글 등록 이후에는 수정할 수 없습니다.
          </div>
        )}
        <Button
          className="bg-transparent text-blue-500"
          onClick={() => setSurveyVisible(true)}
        >
          <Vote /> 설문
        </Button>
      </div>

      <div
        className={`fixed top-0 bottom-0 left-0 right-0 bg-black/30 ${footerVisible ? "block" : "hidden"} z-40`}
        onClick={() => {
          setFooterVisible(false);
          document.body.style.overflow = "auto";
        }}
      />

      {footerVisible && (
        <Footer
          className="mx-auto p-6 max-w-screen-sm fixed left-0 right-0 bottom-0 h-56 bg-gray-50 rounded-t-xl z-50"
          ref={footerRef}
          isVisible={footerVisible}
        >
          <div>
            <div className="mb-2">
              내가 공개할 신원
              <small className="ml-1">(미선택시 공개되지 않습니다)</small>
            </div>
            <div className="mb-3 flex gap-3">
              {attributeCategories
                .filter(({ category }) =>
                  user.attributes.find((a) => a.category === category),
                )
                .map(({ category }) => (
                  <label className="flex items-center" key={category}>
                    <input
                      className="mr-1"
                      type="checkbox"
                      checked={post.author_disclosed_attributes.includes(
                        category,
                      )}
                      onChange={(e) => {
                        setPost((post) => {
                          if (e.target.checked) {
                            post.author_disclosed_attributes.push(category);
                          } else {
                            post.author_disclosed_attributes =
                              post.author_disclosed_attributes.filter(
                                (c) => c !== category,
                              );
                          }
                        });
                      }}
                    />
                    {getAttributeCategoryLabel(category)}
                  </label>
                ))}
            </div>

            <div className="mb-2">
              댓글 작성자가 공개해야 하는 신원
              <small className="ml-1">
                (댓글 작성자는 아래의 신원 정보를 공개해야 합니다)
              </small>
            </div>
            <div className="mb-3 flex gap-3">
              {attributeCategories.map(({ category }) => (
                <label className="flex items-center" key={category}>
                  <input
                    className="mr-1"
                    type="checkbox"
                    checked={post.disclosed_attributes.includes(category)}
                    onChange={(e) => {
                      setPost((post) => {
                        if (e.target.checked) {
                          post.disclosed_attributes.push(category);
                        } else {
                          post.disclosed_attributes =
                            post.disclosed_attributes.filter(
                              (c) => c !== category,
                            );
                        }
                      });
                    }}
                  />
                  {getAttributeCategoryLabel(category)}
                </label>
              ))}
            </div>

            <Button className="float-right" onClick={handleFinalSubmit}>
              완료
            </Button>
          </div>
        </Footer>
      )}
    </div>
  );
}
