import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import api from "../lib/api";
import Button from "../components/Button";
import PageHeader from "../components/PageHeader";
import { AuthContext } from "../contexts/auth";

const userIds = [
  "67416b2c238f5bc1a863d9e7", // 20s male

  "67384c5fa3c3e2617c0883d3", // 40s male

  "67384f8ba3c3e2617c0883ed", // 20s female

  "67384ff5a3c3e2617c0883fd", // no attributes
];

export default function TestLoginPage() {
  const navigate = useNavigate();

  const [userId, setUserId] = useState("");
  const { fetchUser } = useContext(AuthContext);

  // 로그인 핸들러
  const handleLogin = async () => {
    try {
      const res = await api().post("/login-test", { user_id: userId });
      localStorage.setItem("accessToken", res.data.access_token);
      await fetchUser();
      navigate("/");
    } catch (err) {
      console.error(err);
      alert(err);
    }
  };

  return (
    <div>
      <PageHeader title="테스트 로그인" />
      <select value={userId} onChange={(e) => setUserId(e.target.value)}>
        <option defaultChecked hidden>
          사용자 선택
        </option>
        {userIds.map((id) => (
          <option value={id} key={id}>
            {id}
          </option>
        ))}
      </select>
      <Button onClick={handleLogin} disabled={!userId}>
        로그인
      </Button>
    </div>
  );
}
