import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChevronLeft } from "lucide-react";
import Modal from "react-modal";
import { QRCodeSVG } from "qrcode.react";

import PageHeader from "../components/PageHeader";
import { AuthContext } from "../contexts/auth";
import AttributeBadge from "../components/AttributeBadge";
import api from "../lib/api";
import Button from "../components/Button";
import {
  getAttributeCategoryLabel,
  getAttributeLabel,
} from "../lib/attributes";

const MyPage = () => {
  const navigate = useNavigate();
  const { user, fetchUser } = useContext(AuthContext);

  const [attributes, setAttributes] = useState([]);
  const [verifyInfo, setVerifyInfo] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [selectedValue, setSelectedValue] = useState({});

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  // Fetch attributes
  useEffect(() => {
    (async () => {
      try {
        const res = await api().get("/attributes");
        setAttributes(res.data.categories);
      } catch (err) {
        console.error(err);
        alert(err);
      }
    })();
  }, []);

  // 현재 사용자의 신원정보
  const getUserAttributeValue = (category) => {
    return user.attributes.find((attr) => attr.category === category)?.value;
  };

  const handleSelectChange = (category, value) => {
    setSelectedValue((prevSelectedValue) => ({
      ...prevSelectedValue,
      [category]: value,
    }));
  };

  // 인증 버튼 클릭시 invitation url 로드
  const handleVerify = async (category) => {
    const value = selectedValue[category] || getUserAttributeValue(category);

    try {
      console.log(
        "전송되는 데이터\ncategory: " + category + ", value: " + value,
      );

      const res = await api().post("/attributes/attest", {
        category,
        value,
      });

      setVerifyInfo(res.data);
      setModalIsOpen(true); // 인증 요청 후 모달 열기
    } catch (err) {
      console.error(err);
      alert("인증 요청 중 오류가 발생했습니다.");
    }
  };

  // useEffect로 드롭다운 값이 하나인 경우 자동 선택
  useEffect(() => {
    const autoSelectValue = {};
    attributes.forEach((attribute) => {
      if (attribute.values.length === 1) {
        autoSelectValue[attribute.category] = attribute.values[0];
      }
    });
    setSelectedValue((prevSelectedValue) => ({
      ...prevSelectedValue,
      ...autoSelectValue,
    }));
  }, [attributes]);

  return (
    <div className="border-x border-b">
      <PageHeader
        title="마이 페이지"
        addOnLeft={
          <button onClick={() => navigate(-1)}>
            <ChevronLeft />
          </button>
        }
      />

      <div className="p-4">
        <h1 className="mb-2 text-xl font-bold">신원 인증</h1>

        <div className="mb-4 flex items-center gap-1.5 text-sm text-gray-500">
          <div className="font-semibold">내 신원 정보:</div>
          {user.attributes.length > 0 ? (
            user.attributes.map(({ category, value }) => (
              <AttributeBadge category={category} value={value} key={value} />
            ))
          ) : (
            <span>없음</span>
          )}
        </div>

        <div className="flex flex-col gap-2">
          {attributes.map(({ category, values }) => {
            const userValue = getUserAttributeValue(category);

            return (
              <div
                className="flex justify-between items-center gap-6"
                key={category}
              >
                <div className="flex-none w-24 font-semibold">
                  {getAttributeCategoryLabel(category)}
                </div>

                <div className="flex-1">
                  <select
                    className="w-full border border-gray-300 rounded px-2 py-1"
                    value={selectedValue[category]}
                    onChange={(e) =>
                      handleSelectChange(category, e.target.value)
                    }
                  >
                    <option value="" defaultChecked>
                      선택
                    </option>
                    {values.map((value) => (
                      <option value={value} key={value}>
                        {getAttributeLabel(category, value)}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="flex-none w-20">
                  <Button
                    className="w-full"
                    disabled={!selectedValue[category]}
                    onClick={() => handleVerify(category)}
                  >
                    {userValue ? "재인증" : "인증"}
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* 인증 URL이 존재할 경우 모달로 QR 코드 표시 */}
      {verifyInfo && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          ariaHideApp={false}
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)", // 반투명 배경
              zIndex: 1000, // 모달이 다른 요소 위에 보이도록 설정
            },
            content: {
              maxWidth: 400,
              maxHeight: 420,
              margin: "auto", // 화면 중앙 정렬
              padding: "20px", // 내부 여백
              borderRadius: "10px", // 모서리 둥글게
              textAlign: "center", // 텍스트 중앙 정렬
            },
          }}
        >
          <h2 className="text-xl font-semibold mb-4">인증 URL</h2>
          <div className="flex flex-col items-center mb-4">
            <QRCodeSVG value={verifyInfo.short_invitation_url} size={200} />{" "}
            <a
              className="mt-2 text-blue-500"
              href={`didcomm://${verifyInfo.invitation_url}`}
            >
              지갑에서 열기
            </a>
            {/* QR 코드 출력 */}
          </div>
          <Button onClick={() => setModalIsOpen(false)}>닫기</Button>
        </Modal>
      )}
    </div>
  );
};

export default MyPage;
