import { useEffect, useState } from "react";
import Modal from "react-modal";
import { QRCodeSVG } from "qrcode.react";

import Button from "../components/Button";
import PageHeader from "../components/PageHeader";
import api from "../lib/api";

export default function MyAttributesPage() {
  const [attributes, setAttributes] = useState([]);
  const [userAttributes, setUserAttributes] = useState([]);
  const [invitationUrl, setInvitationUrl] = useState(undefined);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  // 각 속성의 선택된 값 관리 (변경된 부분: selectedValue)
  const [selectedValue, setSelectedValue] = useState({});

  // Fetch attributes
  useEffect(() => {
    (async () => {
      try {
        const res = await api().get("/attributes");
        console.log(res.data.categories);
        setAttributes(res.data.categories);

        // 현재 사용자 정보 가져오기 (이미 인증된 내역이 있는지 확인하기 위함)
        const userRes = await api().get("/me");
        setUserAttributes(userRes.data.user.attributes);
      } catch (err) {
        console.error(err);
        alert(err);
      }
    })();
  }, []);

  // 각 카테고리 이름
  const attributeCategoryNames = {
    age: "연령대",
    gender: "성별",
    university_entry_year: "학번",
  };

  // 각 카테고리 값 이름
  const attributeValueNames = {
    age: {
      "20s": "20대",
      "30s": "30대",
      "40s": "40대", // 누락된 값 추가
    },
    gender: {
      male: "남성",
      female: "여성",
    },
  };

  // 현재 사용자의 신원정보
  const getUserAttributeValue = (category) => {
    // 카테고리별 value값
    const attribute = userAttributes.find((attr) => attr.category === category);
    return attribute ? attribute.value : null;
  };

  // 드롭박스에서 선택한 값 업데이트
  const handleSelectChange = (category, value) => {
    console.log(value);
    setSelectedValue((prevSelectedValue) => ({
      ...prevSelectedValue,
      [category]: value,
    }));
  };

  // 인증 버튼 클릭시 invitation url 로드
  const handleVerify = async (category) => {
    const value = selectedValue[category] || getUserAttributeValue(category);
    console.log(value);

    try {
      console.log(
        "전송되는 데이터\ncategory: " + category + ", value: " + value,
      );

      const res = await api().post("/attributes/attest", {
        category,
        value,
      });

      setInvitationUrl(res.data.invitation_url);
      setModalIsOpen(true); // 인증 요청 후 모달 열기
    } catch (err) {
      console.error(err);
      alert("인증 요청 중 오류가 발생했습니다.");
    }
  };

  // useEffect로 드롭다운 값이 하나인 경우 자동 선택
  useEffect(() => {
    const autoSelectValue = {};
    attributes.forEach((attribute) => {
      if (attribute.values.length === 1) {
        autoSelectValue[attribute.category] = attribute.values[0];
      }
    });
    setSelectedValue((prevSelectedValue) => ({
      ...prevSelectedValue,
      ...autoSelectValue,
    }));
  }, [attributes]);

  return (
    <div>
      <PageHeader title="신원 인증하기" />
      <div className="flex flex-col px-24 pt-6">
        <h1 className="text-xl font-semibold pb-3 border-b flex justify-center">
          인증 내역
        </h1>

        {attributes.map((attribute, i) => {
          const userValue = getUserAttributeValue(attribute.category);

          // 값이 하나인 경우 자동 선택
          const selectedCategoryValue =
            selectedValue[attribute.category] ||
            userValue ||
            (attribute.values.length === 1 ? attribute.values[0] : "");

          return (
            <div
              key={i}
              className="flex justify-between items-center gap-6 px-6 py-4 border-b border-dashed border-gray-300 last:border-b-0"
            >
              {/* 카테고리 이름 치환 */}
              <div className="text-lg flex-1">
                {attributeCategoryNames[attribute.category] ||
                  attribute.category}
              </div>

              {/* 값 치환 */}
              <select
                className="border border-gray-300 rounded px-2 py-1 flex-1"
                value={selectedCategoryValue} // 수정된 부분
                onChange={(e) =>
                  handleSelectChange(attribute.category, e.target.value)
                }
              >
                {attribute.values.map((value, index) => (
                  <option key={index} value={value}>
                    {attributeValueNames[attribute.category]?.[value] || value}
                  </option>
                ))}
              </select>

              {/* 버튼 텍스트 조건 */}
              <Button
                className="text-base flex-none"
                onClick={() => handleVerify(attribute.category)}
              >
                {userValue ? "재인증" : "인증"}
              </Button>
            </div>
          );
        })}
      </div>

      {/* 인증 URL이 존재할 경우 모달로 QR 코드 표시 */}
      {invitationUrl && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          ariaHideApp={false}
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)", // 반투명 배경
              zIndex: 1000, // 모달이 다른 요소 위에 보이도록 설정
            },
            content: {
              width: "400px", // 모달 창의 너비
              height: "400px", // 자동 높이
              margin: "auto", // 화면 중앙 정렬
              padding: "20px", // 내부 여백
              borderRadius: "10px", // 모서리 둥글게
              textAlign: "center", // 텍스트 중앙 정렬
            },
          }}
        >
          <h2 className="text-xl font-semibold mb-4">인증 URL</h2>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <QRCodeSVG value={invitationUrl} size={256} /> {/* QR 코드 출력 */}
          </div>
          <Button onClick={() => setModalIsOpen(false)}>닫기</Button>
        </Modal>
      )}
    </div>
  );
}
