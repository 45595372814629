import { useContext } from "react";
import { Navigate } from "react-router-dom";

import { AuthContext } from "../contexts/auth";

export default function AuthorizedPage({ children }) {
  const { loaded, user } = useContext(AuthContext);

  if (!loaded) {
    return null;
  }

  if (!user) {
    alert("로그인이 필요한 페이지입니다.");
    return <Navigate to="/login" />;
  }

  return children;
}
